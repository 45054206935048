import { CAS_USER_LOCAL_STORAGE } from '~/enum/form/constants'
import { LANGUAGE } from '~/enum/language'
import LocaleService from '~/services/LocaleService'
import { getFromLocalStorage, hasInLocalStorage } from '~/utils/sharedHelpers'

export default function ({ $sentry, $axios, $config, app, store, route, req, params, error: nuxtError }) {
  delete $axios.defaults.headers.common.cookie

  let localeService = new LocaleService({ app, store, route, req, params })
  let locale = localeService.getLocale().toLowerCase()

  $axios.setBaseURL($config.apiUrl)
  $axios.setHeader('Accept-Language', locale)

  if (typeof window !== 'undefined') {
    const KEY_STORAGE = CAS_USER_LOCAL_STORAGE

    if (hasInLocalStorage(KEY_STORAGE)) {
      const user = getFromLocalStorage(KEY_STORAGE)
      store.dispatch('casUser/setUserCas', user)
      const token = user?.id_token
      $axios.setToken(token, 'Bearer')
    }
  }

  $axios.onRequest(config => {
    const supportedLangs = Object.values(LANGUAGE)
    const currentLang = config.headers.common['Accept-Language']
    if (!currentLang) {
      const fallback = locale || navigator.languages.find(lang => supportedLangs.includes(lang))
      config.headers.common['Accept-Language'] = fallback
    }

    return config
  })

  $axios.onResponse(response => {
    store.dispatch('header/setAlertInstability', [false])

    return response.data
  })
  $axios.onError(error => {
    $sentry.captureException?.(error)
    if (error.response) {
      if ([500, 404, 400].includes(error?.response?.status)) {
        store.dispatch('header/setAlertInstability', [true, 'alertInstability'])
      }
    } else {
      nuxtError({
        statusCode: error.status,
        message: error.message,
      })
    }
  })
}
