const state = () => ({
  currentArticle: null,
})

const mutations = {
  SET_CURRENT_ARTICLE(state, article) {
    state.currentArticle = article
  },
}

const actions = {
  async fetchArticle({ commit }, id) {
    try {
      const article = await this.$articles.getArticle(id)
      if (!article.id) return

      commit('SET_CURRENT_ARTICLE', article)
    } catch (error) {
      throw new Error('Article not found')
    }
  },
}

const getters = {
  currentArticle: state => state.currentArticle,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
